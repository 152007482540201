/**
 * This is the entry point for Feature Hub App integration
 */

import { FeatureAppDefinition, FeatureAppEnvironment, FeatureServices } from '@feature-hub/core';
import type { Logger } from '@feature-hub/logger';
import { ReactFeatureApp } from '@feature-hub/react';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { ContentServiceV1 } from '@oneaudi/content-service';
import { UniversalEditorProvider } from '@oneaudi/falcon-tools';
import { RenderModeServiceV1 } from '@oneaudi/render-mode-service';
import { FootnoteContextProvider } from '@oneaudi/feature-app-utils';
import {
  AudiFootnoteReferenceServiceInterfaceV3,
  AudiFootnoteRefernceServiceScopeManagerInterfaceV3,
} from '@oneaudi/footnote-reference-service';
import { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import React from 'react';

import App from './FeatureApp';
import { ContentContextProvider } from './context/ContentContext';
import { TrackingServiceContextProvider } from './context/trackingServiceContext';

export interface Dependencies extends FeatureServices {
  readonly 's2:logger'?: Logger;
  readonly 'audi-content-service': ContentServiceV1;
  readonly 'dbad:audi-vue-formatter-service': VueFormatterServiceInterfaceV1;
  readonly 'audi-footnote-reference-service': AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
}

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, Dependencies> = {
  dependencies: {
    featureServices: {
      'dbad:audi-vue-formatter-service': '^1.0.0',
      'audi-footnote-reference-service': '^3.0.0',
    },
    externals: {
      react: '^16.13.1 || ^17.0.2',
      'react-dom': '^16.13.1 || ^17.0.2',
      'styled-components': '*',
    },
  },

  optionalDependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
      'audi-content-service': '^1.0.0',
      'audi-tracking-service': '^2.0.0',
      'audi-render-mode-service': '^1.0.0',
    },
  },

  create: ({ featureServices }: FeatureAppEnvironment<Dependencies, void>) => {
    const logger = featureServices['s2:logger'];
    const contentService = featureServices['audi-content-service'];
    const vueFormatterService = featureServices['dbad:audi-vue-formatter-service'];
    logger?.info(`Feature App ${__FEATURE_APP_NAME__} created.`);
    const referenceServiceScopeManager: AudiFootnoteRefernceServiceScopeManagerInterfaceV3 =
      featureServices['audi-footnote-reference-service'];
    const footnoteReferenceService: AudiFootnoteReferenceServiceInterfaceV3 =
      referenceServiceScopeManager.getDefaultScopeRefService();

    const trackingService = featureServices['audi-tracking-service'] as TrackingServiceV2;
    if (trackingService) {
      trackingService.featureAppName = __FEATURE_APP_NAME__;
    }

    const renderModeService = featureServices['audi-render-mode-service'] as RenderModeServiceV1;

    return {
      render: () => {
        return (
          <FootnoteContextProvider footnoteReferenceService={footnoteReferenceService}>
            <ContentContextProvider
              contentService={contentService}
              vueFormatterService={vueFormatterService}
              logger={logger}
            >
              <TrackingServiceContextProvider trackingService={trackingService}>
                <div data-fefa-custom-id={footnoteReferenceService?.getConsumerId()}>
                  <UniversalEditorProvider
                    contentService={contentService}
                    renderModeService={renderModeService}
                  >
                    <App />
                  </UniversalEditorProvider>
                </div>
              </TrackingServiceContextProvider>
            </ContentContextProvider>
          </FootnoteContextProvider>
        );
      },
    };
  },
};

export default featureAppDefinition;
