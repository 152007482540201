import type {
  EditorJsonContent,
  FaceAsset,
  FadeDirection,
  FalconContent,
  ImageSrcSet,
  LogoShape,
  TileAsset,
  TilesContent,
  VideoSrc,
} from '../contentTypes';
import { emptyImage, emptyLogo, emptyVideo } from './testContent';
import { tileModelToNemo } from './tileModelToNemo';
import { percentageToDecimal } from './utils';

export const mapHeadlessContent = (content: FalconContent): TilesContent => {
  const { layout_name: name, layout_size: size, tiles } = content.fields;
  const appContent: TilesContent = {
    layout: {
      name,
      size,
    },
    tiles: tiles.map((tile) => tileModelToNemo(tile)),
  };
  return appContent;
};

const mapAssetContent = (asset: FaceAsset): TileAsset => {
  if (asset?.assetType === 'image') {
    const smallSrcSet = ['m', 's', 'xs'].map((bp) => ({
      bp,
      src: asset.imageUrlSmall.assetValue,
      srcAsset: '',
    })) as ImageSrcSet;
    const largeSrcSet = ['xxl', 'xl', 'l'].map((bp) => ({
      bp,
      src: asset.imageUrlLarge.assetValue,
      srcAsset: '',
    })) as ImageSrcSet;
    return {
      imageEnabled: true,
      videoEnabled: false,
      image: {
        alt: asset.alt,
        srcSet: [...largeSrcSet, ...smallSrcSet],
      },
      video: {
        alt: '',
        autoplay: false,
        loop: false,
        srcs: {
          xs: { src: '', poster: '' },
          s: { src: '', poster: '' },
          m: { src: '', poster: '' },
          l: { src: '', poster: '' },
          xl: { src: '', poster: '' },
          xxl: { src: '', poster: '' },
        },
      },
    };
  }
  const smallSrcShape = {
    src: asset.videoUrlSmall.assetValue,
    poster: asset.posterImageUrlSmall.assetValue,
  };
  const largeSrcShape = {
    src: asset.videoUrlLarge.assetValue,
    poster: asset.posterImageUrlLarge.assetValue,
  };
  return {
    imageEnabled: false,
    videoEnabled: true,
    image: {
      alt: '',
      srcSet: [],
    },
    video: {
      alt: asset.alt,
      autoplay: asset.autoplay,
      loop: asset.loop,
      srcs: {
        xs: smallSrcShape,
        s: smallSrcShape,
        m: smallSrcShape,
        l: largeSrcShape,
        xl: largeSrcShape,
        xxl: largeSrcShape,
      },
    },
  };
};

export const mapFaceContent = (content: EditorJsonContent): TilesContent => {
  const { layout, tiles } = content;
  const newTiles = tiles.map((tile) => {
    const {
      horizontalAlignment,
      verticalAlignment,
      CTA,
      linkType,
      mediaAsset,
      media,
      logo,
      disclaimers,
      materialDisclaimer,
      zoomEnabled,
      fadeEnabled,
      fade,
      wltpKeys,
      ...restTile
    } = tile;
    const { linkType: CTAlinkType, newWindow: CTAnewWindow, openIn, ...restCTA } = CTA;
    const {
      image,
      imageEnabled,
      videoEnabled,
      newWindow: mediaNewWindow,
      video,
      zoomEnabled: oldZoomEnabled,
      fadeEnabled: oldFadeEnabled,
      fade: oldFade,
      ...restMedia
    } = media;
    const { newImageEnabled, newVideoEnabled } = (() => {
      if (
        (typeof imageEnabled === 'undefined' && typeof videoEnabled === 'undefined') ||
        !(imageEnabled || videoEnabled)
      ) {
        return {
          newImageEnabled: true,
          newVideoEnabled: false,
        };
      }
      return {
        newImageEnabled: imageEnabled,
        newVideoEnabled: videoEnabled,
      };
    })();
    const newLogo = (() => {
      if (logo) {
        const { src: logoSrc, srcAsset: logoSrcAsset, ...restLogo } = logo;
        return {
          src: (typeof logoSrcAsset === 'object' && logoSrcAsset.assetValue) || logoSrc || '',
          srcAsset: { assetValue: '' },
          ...restLogo,
        } as LogoShape;
      }
      return emptyLogo;
    })();
    const newImage = (() => {
      if (image) {
        const { srcSet, ...restImage } = image;
        const newSrcSet = srcSet.map((srcItem) => {
          const newSrc =
            typeof srcItem.srcAsset === 'object' && srcItem.srcAsset?.assetValue
              ? srcItem.srcAsset?.assetValue
              : srcItem.src;
          return { bp: srcItem.bp, src: newSrc, srcAsset: '' };
        });
        return {
          srcSet: newSrcSet,
          ...restImage,
        };
      }
      return emptyImage;
    })();
    const newVideo = (() => {
      if (video) {
        const { srcs, ...restVideo } = video;
        const newVideoSrc = Object.entries(srcs).reduce((acc, [key, value]) => {
          const assetSrc = typeof value?.srcAsset === 'object' && value?.srcAsset?.assetValue;
          const newSrc = assetSrc || value?.src;
          const assetPoster =
            typeof value?.posterAsset === 'object' && value?.posterAsset?.assetValue;
          const newPoster = assetPoster || value?.poster;
          return {
            ...acc,
            [key]: {
              src: newSrc,
              srcAsset: '',
              poster: newPoster,
              posterAsset: '',
            },
          };
        }, {}) as VideoSrc;
        return {
          srcs: newVideoSrc,
          ...restVideo,
        };
      }
      return emptyVideo;
    })();
    const newDisclaimers = (() => {
      if (!disclaimers || disclaimers?.length === 0) {
        return [
          materialDisclaimer?.xs?.disclaimer ||
            materialDisclaimer?.s?.disclaimer ||
            materialDisclaimer?.m?.disclaimer ||
            materialDisclaimer?.l?.disclaimer ||
            materialDisclaimer?.xl?.disclaimer ||
            materialDisclaimer?.xxl?.disclaimer ||
            '',
        ];
      }
      return disclaimers;
    })();
    const { newFadeEnabled, newFade } = (() => {
      if (fadeEnabled) {
        return {
          newFadeEnabled: fadeEnabled,
          newFade: {
            opacity: percentageToDecimal(fade?.opacity || '0%'),
            color: fade?.color || '',
            direction: fade?.direction || 'bottom',
            height: fade?.height || '',
          },
        };
      }
      if (oldFadeEnabled) {
        return {
          newFadeEnabled: oldFadeEnabled,
          newFade: {
            opacity: oldFade?.opacity || '',
            color: oldFade?.color || '',
            direction: oldFade?.direction || 'bottom',
            height: oldFade?.height || '',
          },
        };
      }
      return {
        newFadeEnabled: false,
        newFade: {
          opacity: '',
          color: '',
          direction: 'bottom' as FadeDirection,
          height: '',
        },
      };
    })();
    const assetContent = (() => {
      if (mediaAsset?.assetType === 'image' || mediaAsset?.assetType === 'video') {
        return mapAssetContent(mediaAsset);
      }
      return {
        image: newImage,
        video: newVideo,
        imageEnabled: newImageEnabled,
        videoEnabled: newVideoEnabled,
      };
    })();
    return {
      logo: newLogo,
      mediaAsset,
      media: {
        ...assetContent,
        newWindow: mediaNewWindow,
        fadeEnabled: newFadeEnabled,
        fade: newFade,
        zoomEnabled: zoomEnabled || oldZoomEnabled,
        ...restMedia,
      },
      CTA: {
        linkType: CTAlinkType || linkType,
        newWindow: openIn ? openIn === 'New tab' : CTAnewWindow,
        openIn,
        ...restCTA,
      },
      linkType,
      disclaimers: newDisclaimers,
      ...restTile,
      horizontalAlignment,
      horizontalAlignmentSmall: horizontalAlignment,
      verticalAlignment,
      verticalAlignmentSmall: verticalAlignment,
      zoomEnabled,
      fade,
      fadeEnabled,
      wltpKeys: wltpKeys || [],
    };
  });

  return { layout, tiles: newTiles };
};
