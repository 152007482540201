/* eslint-disable @typescript-eslint/no-explicit-any */
import { VueFormatterFeatureServiceInterfaceV1 } from '@oneaudi/vue-formatter-service/lib/cjs/v1/vue-formatter-feature-service';
import { createContext } from 'react';

type ContentContextState = {
  content: any;
  vueFormatterService: VueFormatterFeatureServiceInterfaceV1;
};

export const ContentContext = createContext<Partial<ContentContextState>>({});
