/* eslint-disable camelcase */
// autogenerated names don't follow camelCase
import type {
  FadeDirection,
  ImageSrcSet,
  MediaImageFragment,
  MediaVideoFragment,
  Tile,
  TileFragment,
} from '../contentTypes';
import { percentageToDecimal } from './utils';

// Image or Video from mediaAsset field
const getMediaAssetContent = (mediaAsset: MediaImageFragment | MediaVideoFragment) => {
  if (mediaAsset?.fields?.assetType === 'image') {
    const { imageUrlSmall, imageUrlLarge } = mediaAsset.fields;
    const smallSrcSet = ['m', 's', 'xs'].map((bp) => ({
      bp,
      src: imageUrlSmall.path,
    })) as ImageSrcSet;
    const largeSrcSet = ['xxl', 'xl', 'l'].map((bp) => ({
      bp,
      src: imageUrlLarge.path,
    })) as ImageSrcSet;
    return {
      imageEnabled: true,
      videoEnabled: false,
      image: {
        alt: mediaAsset?.fields?.alt,
        srcSet: [...largeSrcSet, ...smallSrcSet],
      },
      video: {
        alt: '',
        autoplay: false,
        loop: false,
        srcs: {
          xs: { src: '', poster: '' },
          s: { src: '', poster: '' },
          m: { src: '', poster: '' },
          l: { src: '', poster: '' },
          xl: { src: '', poster: '' },
          xxl: { src: '', poster: '' },
        },
      },
    };
  }
  const { autoplay, loop, videoUrlSmall, videoUrlLarge, posterImageUrlSmall, posterImageUrlLarge } =
    mediaAsset.fields;
  const smallSrcShape = {
    src: videoUrlSmall.path,
    poster: posterImageUrlSmall.path,
  };
  const largeSrcShape = {
    src: videoUrlLarge.path,
    poster: posterImageUrlLarge.path,
  };
  return {
    imageEnabled: false,
    videoEnabled: true,
    image: {
      alt: '',
      srcSet: [
        {
          src: '',
          bp: 'xs',
        },
        {
          src: '',
          bp: 's',
        },
        {
          src: '',
          bp: 'm',
        },
        {
          src: '',
          bp: 'l',
        },
        {
          src: '',
          bp: 'xl',
        },
        {
          src: '',
          bp: 'xxl',
        },
      ] as ImageSrcSet,
    },
    video: {
      alt: mediaAsset?.fields?.alt,
      autoplay,
      loop,
      srcs: {
        xs: smallSrcShape,
        s: smallSrcShape,
        m: smallSrcShape,
        l: largeSrcShape,
        xl: largeSrcShape,
        xxl: largeSrcShape,
      },
    },
  };
};

export const tileModelToNemo = (tile: TileFragment): Tile => {
  const {
    theme,
    icon,
    linkType, // Deprecated
    mediaAsset,
    media_newWindow, // Deprecated
    media_fadeEnabled, // Deprecated
    media_zoomEnabled, // Deprecated
    media_fade_color, // Deprecated
    media_fade_opacity, // Deprecated
    media_fade_height, // Deprecated
    media_fade_direction, // Deprecated
    media_imageEnabled, // Deprecated
    media_videoEnabled, // Deprecated
    media_image_alt, // Deprecated

    media_video_autoplay,
    media_video_loop,
    media_video_srcs_xs_src,
    media_video_srcs_xs_srcAsset,
    media_video_srcs_xs_poster,
    media_video_srcs_xs_posterAsset,
    media_video_srcs_s_src,
    media_video_srcs_s_srcAsset,
    media_video_srcs_s_poster,
    media_video_srcs_s_posterAsset,
    media_video_srcs_m_src,
    media_video_srcs_m_srcAsset,
    media_video_srcs_m_poster,
    media_video_srcs_m_posterAsset,
    media_video_srcs_l_src,
    media_video_srcs_l_srcAsset,
    media_video_srcs_l_poster,
    media_video_srcs_l_posterAsset,
    media_video_srcs_xl_src,
    media_video_srcs_xl_srcAsset,
    media_video_srcs_xl_poster,
    media_video_srcs_xl_posterAsset,
    media_video_srcs_xxl_src,
    media_video_srcs_xxl_srcAsset,
    media_video_srcs_xxl_poster,
    media_video_srcs_xxl_posterAsset,

    logo_alt,
    logo_src,
    logo_srcAsset,
    logo_heights_xs,
    logo_heights_s,
    logo_heights_m,
    logo_heights_l,
    logo_heights_xl,
    logo_heights_xxl,

    heading_text,
    heading_variant,
    heading_htmlTag,
    heading_embolden,

    subHeading_text,
    subHeading_variant,
    subHeading_htmlTag,
    subHeading_embolden,

    CTA_href,
    CTA_variant,
    CTA_text,
    CTA_linkType,
    CTA_openIn,
    CTA_newWindow, // Deprecated

    horizontalAlignment,

    verticalAlignment,

    materialDisclaimer_xs_disclaimer,
    materialDisclaimer_s_disclaimer,
    materialDisclaimer_m_disclaimer,
    materialDisclaimer_l_disclaimer,
    materialDisclaimer_xl_disclaimer,
    materialDisclaimer_xxl_disclaimer,
    wltpKeys,
    disclaimers,

    zoomEnabled,
    fadeEnabled,
    fade_color,
    fade_opacity,
    fade_height,
    fade_direction,

    media_image_srcSet, // Deprecated
  } = tile.fields;

  const newDisclaimers = (() => {
    if (disclaimers.length === 0) {
      return [
        materialDisclaimer_xs_disclaimer ||
          materialDisclaimer_s_disclaimer ||
          materialDisclaimer_m_disclaimer ||
          materialDisclaimer_l_disclaimer ||
          materialDisclaimer_xl_disclaimer ||
          materialDisclaimer_xxl_disclaimer ||
          '',
      ];
    }
    return disclaimers;
  })();

  const newFade = (() => {
    if (fadeEnabled) {
      return {
        color: fade_color || '',
        opacity: percentageToDecimal(fade_opacity) || '',
        height: fade_height || '',
        direction: fade_direction || 'bottom',
      };
    }
    if (media_fadeEnabled) {
      return {
        color: media_fade_color || '',
        opacity: media_fade_opacity || '',
        height: media_fade_height || '',
        direction: media_fade_direction || 'bottom',
      };
    }
    return {
      color: '',
      opacity: '',
      height: '',
      direction: 'bottom' as FadeDirection,
    };
  })();

  // Image or Video from deprecated fields
  const deprecatedMediaContent = {
    imageEnabled: media_imageEnabled,
    videoEnabled: media_videoEnabled,
    image: {
      alt: media_image_alt || '',
      srcSet: media_image_srcSet.map((srcSet) => {
        const src =
          (typeof srcSet.fields?.srcAsset === 'object' && srcSet.fields?.srcAsset?.path) ||
          srcSet.fields?.src ||
          '';
        return {
          ...srcSet.fields,
          src,
          srcAsset: '',
        };
      }),
    },
    video: {
      alt: '',
      autoplay: media_video_autoplay,
      loop: media_video_loop,
      srcs: {
        xs: {
          src: media_video_srcs_xs_srcAsset?.path || media_video_srcs_xs_src || '',
          poster: media_video_srcs_xs_posterAsset?.path || media_video_srcs_xs_poster || '',
        },
        s: {
          src: media_video_srcs_s_srcAsset?.path || media_video_srcs_s_src || '',
          poster: media_video_srcs_s_posterAsset?.path || media_video_srcs_s_poster || '',
        },
        m: {
          src: media_video_srcs_m_srcAsset?.path || media_video_srcs_m_src || '',
          poster: media_video_srcs_m_posterAsset?.path || media_video_srcs_m_poster || '',
        },
        l: {
          src: media_video_srcs_l_srcAsset?.path || media_video_srcs_l_src || '',
          poster: media_video_srcs_l_posterAsset?.path || media_video_srcs_l_poster || '',
        },
        xl: {
          src: media_video_srcs_xl_srcAsset?.path || media_video_srcs_xl_src || '',
          poster: media_video_srcs_xl_posterAsset?.path || media_video_srcs_xl_poster || '',
        },
        xxl: {
          src: media_video_srcs_xxl_srcAsset?.path || media_video_srcs_xxl_src || '',
          poster: media_video_srcs_xxl_posterAsset?.path || media_video_srcs_xxl_poster || '',
        },
      },
    },
  };

  const mediaContent = mediaAsset ? getMediaAssetContent(mediaAsset) : deprecatedMediaContent;

  return {
    CTA: {
      href: CTA_href || '',
      variant: CTA_variant || 'secondary',
      text: CTA_text || '',
      linkType: CTA_linkType || linkType || 'textOnly',
      openIn: CTA_openIn,
      newWindow: CTA_openIn ? CTA_openIn === 'New tab' : CTA_newWindow,
    },
    heading: {
      text: heading_text || '',
      variant: heading_variant,
      htmlTag: heading_htmlTag || 'h1',
      embolden: heading_embolden,
    },
    horizontalAlignment,
    horizontalAlignmentSmall: horizontalAlignment,
    icon: icon || '',
    linkType, // Deprecated
    logo: {
      alt: logo_alt || '',
      src: logo_srcAsset?.path || logo_src || '',
      heights: {
        xs: logo_heights_xs || '',
        s: logo_heights_s || '',
        m: logo_heights_m || '',
        l: logo_heights_l || '',
        xl: logo_heights_xl || '',
        xxl: logo_heights_xxl || '',
      },
    },
    wltpKeys,
    disclaimers: newDisclaimers,
    media: {
      newWindow: media_newWindow, // Deprecated
      fadeEnabled: fadeEnabled || media_fadeEnabled,
      fade: newFade,
      zoomEnabled: zoomEnabled || media_zoomEnabled, // Deprecated
      ...mediaContent,
    },
    subHeading: {
      text: subHeading_text || '',
      variant: subHeading_variant,
      htmlTag: subHeading_htmlTag,
      embolden: subHeading_embolden,
    },
    theme,
    verticalAlignment,
    verticalAlignmentSmall: verticalAlignment,
    zoomEnabled,
    fadeEnabled: fadeEnabled || media_fadeEnabled,
    fade: newFade,
  };
};
