import {
  ThemeProvider,
  Button,
  Text,
  audiDarkTheme,
  audiLightTheme,
  Icon,
} from '@audi/audi-ui-react';
import { Logo, ResponsiveMedia } from '@oneaudi/aoa-shared-editorial-components';
import { UeContainer, UeElement, UeReference } from '@oneaudi/falcon-tools';
import { renderTextWithFootnotesReferences } from '@oneaudi/feature-app-utils';
import { array, arrayOf, bool, func, number, object, oneOf, shape, string } from 'prop-types';
import React from 'react';
import uuid from 'react-uuid';
import styled from 'styled-components';
import { withScrollToElement } from '../../componentWrappers/withScrollToElement';
import WLTPComponent from '../MediaDisclaimerAndWLTP/index';
import {
  CTAsContainer,
  Container,
  ContentContainer,
  DisclaimerWrapper,
  Heading,
  InnerWrapper,
  LogoContainer,
  MediaContainer,
  NoAnchorContainer,
  StyledAnchor,
  SubHeading,
} from './styles';

const StyledAssetWrapper = styled.div`
  display: grid;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const ButtonWithScrollToElement = withScrollToElement(Button);

function Anchor({ linkEnabled, href, newWindow, onClick, children, ...rest }) {
  if (linkEnabled) {
    return (
      <StyledAnchor href={href} target={newWindow ? '_blank' : '_self'} onClick={onClick} {...rest}>
        {children}
      </StyledAnchor>
    );
  }

  return <NoAnchorContainer>{children}</NoAnchorContainer>;
}

Anchor.displayName = 'Anchor';

const AnchorWithScroll = withScrollToElement(Anchor);

const stopPropagation = (e) => {
  const { currentTarget } = e;
  if (currentTarget.getAttribute('href')) {
    e.stopPropagation();
  }
};

export function ContentTile({
  CTA,
  disclaimers,
  heading,
  horizontalAlignment,
  horizontalAlignmentSmall,
  icon,
  logo,
  media,
  mobileBreakpointSizes,
  size,
  subHeading,
  theme,
  verticalAlignment,
  verticalAlignmentSmall,
  onClickEvent,
  index,
  layoutName,
  wltpKeys,
  ...ueProps
}) {
  const assetContainerLabel = `${media.imageEnabled ? 'Image' : 'Video'} asset`;
  return (
    <AnchorWithScroll
      linkEnabled={!CTA.linkType || CTA.linkType === 'fullTile'}
      href={CTA.href}
      newWindow={CTA.newWindow}
      data-testid="content-tile-image-anchor"
      onClick={onClickEvent}
      {...ueProps}
    >
      <ThemeProvider theme={theme === 'light' ? audiLightTheme : audiDarkTheme}>
        <Container
          data-testid="content-tile-container"
          index={index}
          size={size}
          layoutName={layoutName}
          mobileBreakpointSizes={mobileBreakpointSizes}
          zoomEnabled={media.zoomEnabled}
          {...ueProps}
        >
          <InnerWrapper
            data-testid="content-tile-inner-wrapper"
            verticalAlignment={verticalAlignment}
            verticalAlignmentSmall={verticalAlignmentSmall}
            horizontalAlignment={horizontalAlignment}
            horizontalAlignmentSmall={horizontalAlignmentSmall}
          >
            <MediaContainer>
              <UeContainer propertyPath={`tiles.${index}.mediaAsset`} label={assetContainerLabel}>
                <StyledAssetWrapper>
                  <UeReference propertyPath={`tiles.${index}.mediaAsset`} behavior="component">
                    <ResponsiveMedia {...media} cover />
                  </UeReference>
                </StyledAssetWrapper>
              </UeContainer>
            </MediaContainer>
            <ContentContainer {...ueProps}>
              <Anchor
                linkEnabled={CTA.linkType === 'textOnly'}
                href={CTA.href}
                newWindow={CTA.newWindow}
                data-testid="content-tile-text-anchor"
                onClick={onClickEvent}
              >
                <LogoContainer
                  horizontalAlignment={horizontalAlignment}
                  horizontalAlignmentSmall={horizontalAlignmentSmall}
                >
                  {logo.src && !icon && (
                    <Logo src={logo.src} alt={logo.alt} heights={logo.heights} />
                  )}
                  {!logo.src && icon && <Icon name={icon} size="large" variant="primary" />}
                </LogoContainer>
                {heading.text && (
                  <Heading
                    horizontalAlignment={horizontalAlignment}
                    horizontalAlignmentSmall={horizontalAlignmentSmall}
                  >
                    <Text variant={heading.variant} as={heading.htmlTag}>
                      <DisclaimerWrapper onClick={stopPropagation}>
                        <UeElement type="text" property="heading_text" label="Heading">
                          {renderTextWithFootnotesReferences(heading.text)}
                        </UeElement>
                      </DisclaimerWrapper>
                    </Text>
                  </Heading>
                )}
                {subHeading.text && !logo.src && !icon && (
                  <SubHeading
                    horizontalAlignment={horizontalAlignment}
                    horizontalAlignmentSmall={horizontalAlignmentSmall}
                  >
                    <Text variant={subHeading.variant} as={subHeading.htmlTag}>
                      <DisclaimerWrapper onClick={stopPropagation}>
                        <UeElement type="text" property="subHeading_text" label="Subheading">
                          {renderTextWithFootnotesReferences(subHeading.text)}
                        </UeElement>
                      </DisclaimerWrapper>
                    </Text>
                  </SubHeading>
                )}
              </Anchor>
              {CTA.text && (
                <CTAsContainer
                  horizontalAlignment={horizontalAlignment}
                  horizontalAlignmentSmall={horizontalAlignmentSmall}
                >
                  <ButtonWithScrollToElement
                    variant={CTA.variant}
                    key={uuid()}
                    href={CTA.linkType === 'textOnly' ? CTA.href : ''}
                    newWindow={CTA.newWindow}
                    data-testid="content-tile-cta"
                    onClick={onClickEvent}
                  >
                    <UeElement type="text" property="CTA_text" label="CTA">
                      {CTA.text}
                    </UeElement>
                  </ButtonWithScrollToElement>
                </CTAsContainer>
              )}
            </ContentContainer>
          </InnerWrapper>
        </Container>
        <WLTPComponent theme={theme} materialDisclaimer={disclaimers} wltpTextValues={wltpKeys} />
      </ThemeProvider>
    </AnchorWithScroll>
  );
}

ContentTile.displayName = 'ContentTile';

const srcShape = shape({
  src: string.isRequired,
  poster: string.isRequired,
});

ContentTile.propTypes = {
  size: number, // Value to be applied to the height of the tile
  mobileBreakpointSizes: object, // Values to be applied to the height of the tile at 375 & 768 breakpoints
  theme: oneOf(['light', 'dark']).isRequired,
  linkType: oneOf(['fullTile', 'textOnly', 'noLink']),
  icon: string,
  media: shape({
    newWindow: bool,
    fadeEnabled: bool,
    fade: shape({
      color: string, // hex color, if none present will use theme color
      opacity: string, // 0 -1
      height: string, // percentage 0-100
      direction: oneOf(['top', 'bottom', 'left', 'right']),
    }),
    imageEnabled: bool.isRequired,
    videoEnabled: bool.isRequired,
    image: shape({
      alt: string,
      srcSet: arrayOf(
        shape({
          bp: oneOf(['xs', 's', 'm', 'l', 'xl', 'xxl']),
          src: string,
        }),
      ),
    }),
    video: shape({
      autoplay: bool,
      loop: bool,
      srcs: shape({
        xs: srcShape,
        s: srcShape,
        m: srcShape,
        l: srcShape,
        xl: srcShape,
        xxl: srcShape,
      }),
    }),
  }).isRequired,
  logo: shape({
    alt: string,
    src: string,
  }),
  heading: shape({
    text: string,
    variant: oneOf(['order1', 'order2', 'order3', 'order4', 'display1', 'display2']),
    htmlTag: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    embolden: bool,
  }),
  subHeading: shape({
    text: string,
    variant: oneOf(['order1', 'order2', 'order3', 'order4', 'display1', 'display2']),
    htmlTag: oneOf(['h2', 'h3', 'h4', 'h5', 'h6', 'p']),
    embolden: bool,
  }),
  CTA: shape({
    href: string,
    variant: oneOf(['primary', 'secondary', 'text']),
    text: string,
    newWindow: bool,
  }),
  horizontalAlignment: oneOf(['left', 'center', 'right']),
  horizontalAlignmentSmall: oneOf(['left', 'center', 'right']),
  verticalAlignment: oneOf(['top', 'center', 'bottom']),
  verticalAlignmentSmall: oneOf(['top', 'center', 'bottom']),
  wltpKeys: array,
  disclaimers: array,
  onClickEvent: func,
};
