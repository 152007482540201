import { ImageShape, LogoShape } from '../contentTypes';

export const mockedFalconContent = {
  __type: 'aem-headless',
  id: 'mockedid',
  path: 'mockedpath',
  status: 'DRAFT',
  created: '2024-01-22T16:28:10.333Z',
  modified: '2024-01-22T16:28:48.858Z',
  title: 'Test Content',
  description: '',
  model: {
    id: 'mockedid',
    path: 'mockedId',
    title: 'Mocked Content',
    metadata: {
      baseUrl: 'http://fake.url/dev',
      id: 'mockedid',
      serverSrc: 'fh/app.node.js',
      src: 'fh/app.js',
      version: 'dev',
    },
  },
  fields: {
    tiles: [
      {
        id: 'mockedid',
        path: 'mockedpath',
        status: 'DRAFT',
        created: '2024-01-22T16:26:02.704Z',
        modified: '2024-01-22T16:29:32.548Z',
        title: 'Tile 1',
        description: '',
        model: {
          id: 'mockedid',
          path: 'mockedpath',
          title: 'tile model',
          metadata: {
            baseUrl: 'http://fake.url/dev',
            id: 'mockedid',
            serverSrc: 'fh/app.node.js',
            src: 'fh/app.js',
            version: 'dev',
          },
        },
        fields: {
          theme: 'dark',
          mediaAsset: {
            id: 'mockedid',
            path: 'mockedpath',
            status: 'DRAFT',
            created: '2024-01-22T16:08:41.287Z',
            modified: '2024-01-22T16:18:17.990Z',
            title: 'Tile 1 srcset',
            description: '',
            model: {
              id: 'mockedid',
              path: 'mockedpath',
              title: 'srcset model',
              metadata: {
                baseUrl: 'http://fake.url/dev',
                id: 'mockedid',
                serverSrc: 'fh/app.node.js',
                src: 'fh/app.js',
                version: 'dev',
              },
            },
            fields: {
              assetType: 'image',
              alt: 'metrics of progress',
              imageUrlSmall: {
                path: 'https://s7test1.scene7.com/is/image/audinarstage/teams/narwhal/assets/metrics_of_progress_poster_500p.png',
              },
              imageUrlLarge: {
                path: 'https://s7test1.scene7.com/is/image/audinarstage/teams/narwhal/assets/metrics_of_progress_poster_720p.png',
              },
            },
          },
          linkType: 'noLink', // Deprecated
          media_newWindow: false, // Deprecated
          media_fadeEnabled: false, // Deprecated
          media_zoomEnabled: true, // Deprecated
          media_imageEnabled: true, // Deprecated
          media_videoEnabled: false, // Deprecated
          media_image_srcSet: [
            // Deprecated
            {
              id: 'mockedid',
              path: 'mockedpath',
              status: 'DRAFT',
              created: '2024-01-22T16:08:41.287Z',
              modified: '2024-01-22T16:18:17.990Z',
              title: 'Tile 1 srcset',
              description: '',
              model: {
                id: 'mockedid',
                path: 'mockedpath',
                title: 'srcset model',
                metadata: {
                  baseUrl: 'http://fake.url/dev',
                  id: 'mockedid',
                  serverSrc: 'fh/app.node.js',
                  src: 'fh/app.js',
                  version: 'dev',
                },
              },
              fields: {
                src: '',
                srcAsset: {
                  path: 'https://www.audiusa.com/content/dam/nemo/us/Homepage/1277x718_M04.1_exterior_3-4_front_14_final_RGB-min.jpg?output-format=webp&downsize=1277px:*',
                },
                bp: 'xs',
              },
            },
          ],
          media_video_srcs_xs_src: '', // Deprecated
          media_video_srcs_xs_srcAsset: { path: '' }, // Deprecated
          media_video_srcs_xs_poster: '', // Deprecated
          media_video_srcs_xs_posterAsset: { path: '' }, // Deprecated
          media_video_srcs_s_src: '', // Deprecated
          media_video_srcs_s_srcAsset: { path: '' }, // Deprecated
          media_video_srcs_s_poster: '', // Deprecated
          media_video_srcs_s_posterAsset: { path: '' }, // Deprecated
          media_video_srcs_m_src: '', // Deprecated
          media_video_srcs_m_srcAsset: { path: '' }, // Deprecated
          media_video_srcs_m_poster: '', // Deprecated
          media_video_srcs_m_posterAsset: { path: '' }, // Deprecated
          media_video_srcs_l_src: '', // Deprecated
          media_video_srcs_l_srcAsset: { path: '' }, // Deprecated
          media_video_srcs_l_poster: '', // Deprecated
          media_video_srcs_l_posterAsset: { path: '' }, // Deprecated
          media_video_srcs_xl_src: '', // Deprecated
          media_video_srcs_xl_srcAsset: { path: '' }, // Deprecated
          media_video_srcs_xl_poster: '', // Deprecated
          media_video_srcs_xl_posterAsset: { path: '' }, // Deprecated
          media_video_srcs_xxl_src: '', // Deprecated
          media_video_srcs_xxl_srcAsset: { path: '' }, // Deprecated
          media_video_srcs_xxl_poster: '', // Deprecated
          media_video_srcs_xxl_posterAsset: { path: '' }, // Deprecated
          media_video_autoplay: false, // Deprecated
          media_video_loop: false, // Deprecated
          heading_text: 'NoLink Tile without href',
          heading_variant: 'order1',
          heading_embolden: false,
          subHeading_text: 'Test Subtitle',
          subHeading_variant: 'order2',
          subHeading_embolden: false,
          subHeading_htmlTag: 'h4',
          CTA_newWindow: false, // Deprecated
          CTA_linkType: 'noLink',
          CTA_openIn: 'Same tab',
          disclaimers: ['test disclaimer'],
          horizontalAlignment: 'center',
          horizontalAlignmentSmall: 'center', // Deprecated
          verticalAlignment: 'center',
          verticalAlignmentSmall: 'center', // Deprecated
          wltpKeys: ['q3', 'a8etron'],
          zoomEnabled: true,
          fadeEnabled: true,
          fade_direction: 'bottom',
          fade_height: '70%',
          fade_opacity: '60%',
          fade_color: '#000000',
        },
      },
      {
        id: 'mockedid',
        path: 'mockedpath',
        status: 'DRAFT',
        created: '2024-01-22T16:20:31.564Z',
        modified: '2024-01-22T16:25:32.339Z',
        title: 'Tile 2',
        description: '',
        model: {
          id: 'mockedid',
          path: 'mockedpath',
          title: 'Tile Model',
          metadata: {
            baseUrl: 'http://fake.url/dev',
            id: 'mockedid',
            serverSrc: 'fh/app.node.js',
            src: 'fh/app.js',
            version: 'dev',
          },
        },
        fields: {
          theme: 'dark',
          mediaAsset: {
            id: 'mockedid',
            path: 'mockedpath',
            status: 'DRAFT',
            created: '2024-01-22T16:18:52.529Z',
            modified: '2024-01-22T16:19:08.228Z',
            title: 'Tile 2 srcSet',
            description: '',
            model: {
              id: 'mockedid',
              path: 'mockedpath',
              title: 'srcset model',
              metadata: {
                baseUrl: 'http://fake.url/dev',
                id: 'mockedid',
                serverSrc: 'fh/app.node.js',
                src: 'fh/app.js',
                version: 'dev',
              },
            },
            fields: {
              assetType: 'video',
              alt: 'etron',
              autoplay: false,
              loop: false,
              videoUrlSmall: {
                path: 'https://s7test1.scene7.com/is/content/audinarstage/teams/narwhal/assets/metrics_of_progress_Content500p-Web.mp4',
              },
              videoUrlLarge: {
                path: 'https://s7test1.scene7.com/is/content/audinarstage/teams/narwhal/assets/metrics_of_progress_Content1080p-Web.mp4',
              },
              posterImageUrlSmall: {
                path: 'https://s7test1.scene7.com/is/image/audinarstage/teams/narwhal/assets/metrics_of_progress_poster_500p.png',
              },
              posterImageUrlLarge: {
                path: 'https://s7test1.scene7.com/is/image/audinarstage/teams/narwhal/assets/metrics_of_progress_poster_720p.png',
              },
            },
          },
          linkType: 'noLink', // Deprecated
          media_newWindow: false, // Deprecated
          media_fadeEnabled: false, // Deprecated
          media_zoomEnabled: false, // Deprecated
          media_imageEnabled: true, // Deprecated
          media_videoEnabled: false, // Deprecated
          media_image_srcSet: [
            // Deprecated
            {
              id: 'mockedid',
              path: 'mockedpath',
              status: 'DRAFT',
              created: '2024-01-22T16:18:52.529Z',
              modified: '2024-01-22T16:19:08.228Z',
              title: 'Tile 2 srcSet',
              description: '',
              model: {
                id: 'mockedid',
                path: 'mockedpath',
                title: 'srcset model',
                metadata: {
                  baseUrl: 'http://fake.url/dev',
                  id: 'mockedid',
                  serverSrc: 'fh/app.node.js',
                  src: 'fh/app.js',
                  version: 'dev',
                },
              },
              fields: {
                srcAsset: {
                  path: 'https://www.audiusa.com/dam/nemo/us/models/e-tron/e-tron-sportback/730x730-overview-images/730x730-Audi-etronsb-accessories-min.jpg?output-format=webp&downsize=730px:*',
                },
                bp: 'xs',
              },
            },
          ],
          media_video_autoplay: false, // Deprecated
          media_video_loop: false, // Deprecated
          media_video_srcs_xs_src: '', // Deprecated
          media_video_srcs_xs_srcAsset: { path: '' }, // Deprecated
          media_video_srcs_xs_poster: '', // Deprecated
          media_video_srcs_xs_posterAsset: { path: '' }, // Deprecated
          media_video_srcs_s_src: '', // Deprecated
          media_video_srcs_s_srcAsset: { path: '' }, // Deprecated
          media_video_srcs_s_poster: '', // Deprecated
          media_video_srcs_s_posterAsset: { path: '' }, // Deprecated
          media_video_srcs_m_src: '', // Deprecated
          media_video_srcs_m_srcAsset: { path: '' }, // Deprecated
          media_video_srcs_m_poster: '', // Deprecated
          media_video_srcs_m_posterAsset: { path: '' }, // Deprecated
          media_video_srcs_l_src: '', // Deprecated
          media_video_srcs_l_srcAsset: { path: '' }, // Deprecated
          media_video_srcs_l_poster: '', // Deprecated
          media_video_srcs_l_posterAsset: { path: '' }, // Deprecated
          media_video_srcs_xl_src: '', // Deprecated
          media_video_srcs_xl_srcAsset: { path: '' }, // Deprecated
          media_video_srcs_xl_poster: '', // Deprecated
          media_video_srcs_xl_posterAsset: { path: '' }, // Deprecated
          media_video_srcs_xxl_src: '', // Deprecated
          media_video_srcs_xxl_srcAsset: { path: '' }, // Deprecated
          media_video_srcs_xxl_poster: '', // Deprecated
          media_video_srcs_xxl_posterAsset: { path: '' }, // Deprecated
          heading_text: 'NoLink Tile without href',
          heading_variant: 'order1',
          heading_embolden: false,
          subHeading_text: 'Test Subtitle',
          subHeading_variant: 'order2',
          subHeading_embolden: false,
          subHeading_htmlTag: 'h4',
          CTA_href: 'fakelink',
          CTA_variant: 'secondary',
          CTA_newWindow: false, // Deprecated
          CTA_linkType: 'noLink',
          CTA_openIn: 'Same tab',
          disclaimers: ['test disclaimer'],
          horizontalAlignment: 'center',
          horizontalAlignmentSmall: 'center', // Deprecated
          verticalAlignment: 'center',
          verticalAlignmentSmall: 'center', // Deprecated
          wltpKeys: ['q3', 'a8etron'],
          zoomEnabled: false,
        },
      },
    ],
    layout_name: 'Basic - 2x1',
    layout_size: 'standard',
  },
};

export const mockedMappedContent = {
  layout: { name: 'Basic - 2x1', size: 'standard' },
  tiles: [
    {
      CTA: {
        href: '',
        variant: 'secondary',
        text: '',
        linkType: 'noLink',
        openIn: 'Same tab',
        newWindow: false,
      },
      disclaimers: ['test disclaimer'],
      heading: {
        text: 'NoLink Tile without href',
        variant: 'order1',
        htmlTag: 'h1',
        embolden: false,
      },
      horizontalAlignment: 'center',
      horizontalAlignmentSmall: 'center',
      icon: '',
      linkType: 'noLink',
      logo: {
        src: '',
        alt: '',
        heights: {
          xs: '',
          s: '',
          m: '',
          l: '',
          xl: '',
          xxl: '',
        },
      },
      media: {
        newWindow: false, // Deprecated
        fadeEnabled: true,
        fade: {
          color: '#000000',
          opacity: '0.6',
          height: '70%',
          direction: 'bottom',
        },
        imageEnabled: true,
        videoEnabled: false,
        zoomEnabled: true, // Deprecated
        image: {
          alt: 'metrics of progress',
          srcSet: [
            {
              bp: 'xxl',
              src: 'https://s7test1.scene7.com/is/image/audinarstage/teams/narwhal/assets/metrics_of_progress_poster_720p.png',
            },
            {
              bp: 'xl',
              src: 'https://s7test1.scene7.com/is/image/audinarstage/teams/narwhal/assets/metrics_of_progress_poster_720p.png',
            },
            {
              bp: 'l',
              src: 'https://s7test1.scene7.com/is/image/audinarstage/teams/narwhal/assets/metrics_of_progress_poster_720p.png',
            },
            {
              bp: 'm',
              src: 'https://s7test1.scene7.com/is/image/audinarstage/teams/narwhal/assets/metrics_of_progress_poster_500p.png',
            },
            {
              bp: 's',
              src: 'https://s7test1.scene7.com/is/image/audinarstage/teams/narwhal/assets/metrics_of_progress_poster_500p.png',
            },
            {
              bp: 'xs',
              src: 'https://s7test1.scene7.com/is/image/audinarstage/teams/narwhal/assets/metrics_of_progress_poster_500p.png',
            },
          ],
        },
        video: {
          alt: '',
          autoplay: false,
          loop: false,
          srcs: {
            xs: {
              poster: '',
              src: '',
            },
            s: {
              poster: '',
              src: '',
            },
            m: {
              poster: '',
              src: '',
            },
            l: {
              poster: '',
              src: '',
            },
            xl: {
              poster: '',
              src: '',
            },
            xxl: {
              poster: '',
              src: '',
            },
          },
        },
      },
      subHeading: { text: 'Test Subtitle', variant: 'order2', embolden: false, htmlTag: 'h4' },
      theme: 'dark',
      verticalAlignment: 'center',
      verticalAlignmentSmall: 'center',
      wltpKeys: ['q3', 'a8etron'],
      zoomEnabled: true,
      fadeEnabled: true,
      fade: {
        color: '#000000',
        opacity: '0.6',
        height: '70%',
        direction: 'bottom',
      },
    },
    {
      CTA: {
        href: 'fakelink',
        variant: 'secondary',
        text: '',
        newWindow: false,
        linkType: 'noLink',
        openIn: 'Same tab',
      },
      disclaimers: ['test disclaimer'],
      heading: {
        text: 'NoLink Tile without href',
        variant: 'order1',
        htmlTag: 'h1',
        embolden: false,
      },
      horizontalAlignment: 'center',
      horizontalAlignmentSmall: 'center',
      icon: '',
      linkType: 'noLink',
      logo: {
        src: '',
        alt: '',
        heights: {
          xs: '',
          s: '',
          m: '',
          l: '',
          xl: '',
          xxl: '',
        },
      },
      media: {
        newWindow: false, // Deprecated
        fadeEnabled: false,
        fade: {
          color: '',
          opacity: '',
          height: '',
          direction: 'bottom',
        },
        imageEnabled: false,
        videoEnabled: true,
        zoomEnabled: false, // Deprecated
        image: {
          alt: '',
          srcSet: [
            {
              bp: 'xs',
              src: '',
            },
            {
              bp: 's',
              src: '',
            },
            {
              bp: 'm',
              src: '',
            },
            {
              bp: 'l',
              src: '',
            },
            {
              bp: 'xl',
              src: '',
            },
            {
              bp: 'xxl',
              src: '',
            },
          ],
        },
        video: {
          alt: 'etron',
          autoplay: false,
          loop: false,
          srcs: {
            l: {
              poster:
                'https://s7test1.scene7.com/is/image/audinarstage/teams/narwhal/assets/metrics_of_progress_poster_720p.png',
              src: 'https://s7test1.scene7.com/is/content/audinarstage/teams/narwhal/assets/metrics_of_progress_Content1080p-Web.mp4',
            },
            m: {
              poster:
                'https://s7test1.scene7.com/is/image/audinarstage/teams/narwhal/assets/metrics_of_progress_poster_500p.png',
              src: 'https://s7test1.scene7.com/is/content/audinarstage/teams/narwhal/assets/metrics_of_progress_Content500p-Web.mp4',
            },
            s: {
              poster:
                'https://s7test1.scene7.com/is/image/audinarstage/teams/narwhal/assets/metrics_of_progress_poster_500p.png',
              src: 'https://s7test1.scene7.com/is/content/audinarstage/teams/narwhal/assets/metrics_of_progress_Content500p-Web.mp4',
            },
            xl: {
              poster:
                'https://s7test1.scene7.com/is/image/audinarstage/teams/narwhal/assets/metrics_of_progress_poster_720p.png',
              src: 'https://s7test1.scene7.com/is/content/audinarstage/teams/narwhal/assets/metrics_of_progress_Content1080p-Web.mp4',
            },
            xs: {
              poster:
                'https://s7test1.scene7.com/is/image/audinarstage/teams/narwhal/assets/metrics_of_progress_poster_500p.png',
              src: 'https://s7test1.scene7.com/is/content/audinarstage/teams/narwhal/assets/metrics_of_progress_Content500p-Web.mp4',
            },
            xxl: {
              poster:
                'https://s7test1.scene7.com/is/image/audinarstage/teams/narwhal/assets/metrics_of_progress_poster_720p.png',
              src: 'https://s7test1.scene7.com/is/content/audinarstage/teams/narwhal/assets/metrics_of_progress_Content1080p-Web.mp4',
            },
          },
        },
      },
      subHeading: { text: 'Test Subtitle', variant: 'order2', embolden: false, htmlTag: 'h4' },
      theme: 'dark',
      verticalAlignment: 'center',
      verticalAlignmentSmall: 'center',
      wltpKeys: ['q3', 'a8etron'],
      zoomEnabled: false,
      fadeEnabled: false,
      fade: {
        color: '',
        opacity: '',
        height: '',
        direction: 'bottom',
      },
    },
  ],
};

export const emptyLogo = {
  alt: '',
  src: '',
  srcAsset: '',
  heights: {
    xs: '18',
    s: '18',
    m: '24',
    l: '36',
    xl: '48',
    xxl: '60',
  },
} as LogoShape;

export const emptyImage = {
  alt: '',
  srcSet: [
    {
      bp: 'xs',
      src: '',
      srcAsset: '',
    },
  ],
} as ImageShape;

export const emptyVideo = {
  alt: '',
  autoplay: true,
  loop: true,
  srcs: {
    xs: {
      src: '',
      srcAsset: '',
      poster: '',
      posterAsset: '',
    },
    s: {
      src: '',
      srcAsset: '',
      poster: '',
      posterAsset: '',
    },
    m: {
      src: '',
      srcAsset: '',
      poster: '',
      posterAsset: '',
    },
    l: {
      src: '',
      srcAsset: '',
      poster: '',
      posterAsset: '',
    },
    xl: {
      src: '',
      srcAsset: '',
      poster: '',
      posterAsset: '',
    },
    xxl: {
      src: '',
      srcAsset: '',
      poster: '',
      posterAsset: '',
    },
  },
};
