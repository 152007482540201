import styled from 'styled-components';

const constants = {
  color: {
    activeSlideColor: '#BB0A30',
    black: '#000000',
    darkGrey: '#333333',
    grey: '#999999',
    lightGrey: '#F2F2F2',
    white: '#FFFFFF',
  },
  displaySizes: {
    mobile: '375px',
    tablet: '768px',
    smallDesktop: '1024px',
    desktop: '1440px',
    largeDesktop: '1920px',
  },
  theme: {
    darkTheme: 'darkTheme',
    lightTheme: 'lightTheme',
  },
};

interface WLTPContainerProps {
  theme: string;
}

const device = {
  desktop: `(min-width: ${constants.displaySizes.desktop})`,
  largeDesktop: `(min-width: ${constants.displaySizes.largeDesktop})`,
  mobile: `(min-width: ${constants.displaySizes.mobile})`,
  smallDesktop: `(min-width: ${constants.displaySizes.smallDesktop})`,
  tablet: `(min-width: ${constants.displaySizes.tablet})`,
  smallTablet: `(max-width: ${constants.displaySizes.tablet})`,
};

export const WLTPContainer = styled.div<WLTPContainerProps>`
  background-color: ${(props) =>
    props.theme === constants.theme.darkTheme ? constants.color.black : constants.color.white};
  padding-bottom: 24px;

  .fg-wltp-items {
    margin: 0 5px 0 0;
  }

  p span sup {
    font-size: 8px;
  }

  @media ${device.mobile} {
    p {
      color: ${(props) =>
        props.theme === constants.theme.darkTheme ? constants.color.white : constants.color.black};
    }
  }
`;

export const MediaDisclaimerContainer = styled.div`
  width: 100%;
  p sup {
    font-size: 8px;
    font-feature-settings: normal;
    font-weight: 700;
  }
`;
